import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';

import { IMigrationFeatureFlags } from '@core';
import { SharedModule } from './shared/shared.module';
import { NoopComponent } from './shared/noop/noop.component';
import { CustomerUserManagementComponent } from './admin/customer-user/customer-user-management.component';

const routes: Routes = [
  {
    path: '', // TODO: Solve circular dependency issue with lazy loaded routes.
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: '**',
    component: NoopComponent,
    data: { isWildcard: true },
  },
];

export const migrationFeatureFlagsRoutes: IMigrationFeatureFlags<Route> = {
  adminCustomer: {
    path: 'admin/customer/:customerId/users',
    component: CustomerUserManagementComponent,
  },
};

@NgModule({
  imports: [RouterModule.forRoot(routes), SharedModule],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FacadeRegisterService {
  private hash: Record<string, unknown>;

  constructor() {
    this.hash = {};
  }

  getFacade(featureKey: string): unknown {
    return this.hash[featureKey];
  }

  registerFacade(featureKey: string, facade: unknown) {
    this.hash[featureKey] = facade;
  }
}

import { createAction, props } from '@ngrx/store';
import {
  NewProjectRequest,
  NewProjectResponse,
} from '../shared/projects.service';
import { ProjectsEntity } from './projects.models';

export const findAll = createAction(
  '[Projects Page] Find All Projects',
  props<{ customerId: string; limit: number }>()
);

export const findAllProjectsSuccess = createAction(
  '[Projects/API] Find All Projects Success',
  props<{ projects: ProjectsEntity[] }>()
);

export const findAllProjectsFailure = createAction(
  '[Projects/API] Find All Projects Failure',
  props<{ error: any }>()
);

export const updateProject = createAction(
  '[Projects/API] Update Project',
  props<{
    projectId: string;
    projectData: Partial<ProjectsEntity>;
  }>()
);

export const updateProjectSuccess = createAction(
  '[Projects/API] Update Project Success',
  props<{
    projectId: string;
    updatedProject: Partial<ProjectsEntity>;
  }>()
);

export const updateProjectFailure = createAction(
  '[Projects/API] Update Project Failure',
  props<{ error: any }>()
);

export const createProject = createAction(
  '[Projects/API] Create Project',
  props<{
    newProject: NewProjectRequest;
  }>()
);

export const createProjectSuccess = createAction(
  '[Projects/API] Create Project Success',
  props<{
    createdProject: NewProjectResponse;
  }>()
);

export const createProjectFailure = createAction(
  '[Projects/API] Create Project Failure',
  props<{ error: any }>()
);

export const find = createAction(
  '[Projects/Page] Find Project',
  props<{ projectId: string }>()
);

export const findProjectsSuccess = createAction(
  '[Projects/API] Find Project Success',
  props<{ project: ProjectsEntity }>()
);

export const findProjectsFailure = createAction(
  '[Projects/API] Find Project Failure',
  props<{ error: any }>()
);

export const selectProject = createAction(
  '[Projects/Page] Select Project',
  props<{ projectId: string }>()
);

import { Injectable, NgZone } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import type { IAngularStatic } from 'angular';

import { FacadeRegisterService } from '../facade-register/facade-register.service';

import { IV8Environemnt } from '../../../environments/utils';
import {
  MigrationFeatureFlagsKeys,
  MigrationFlagService,
} from '../migration-flag/migration-flag.service';

// Put here the custom object inject into the window object
export interface IV8Window extends Window {
  bridge?: {
    zone: NgZone;
    store: {
      getFacade: (facadeKey: string) => unknown;
    };
  };
  __V8ENV?: IV8Environemnt;
  angular: IAngularStatic;
  migratonUtils?: {
    setFeatureFlag: (key: MigrationFeatureFlagsKeys, value: boolean) => void;
  };
}

@Injectable({
  providedIn: 'root',
})
export class WindowRefService {
  constructor(
    private zone: NgZone,
    private upgrade: UpgradeModule,
    private facadeRegister: FacadeRegisterService,
    private migrationFeatureFlagService: MigrationFlagService
  ) {}

  get nativeWindow(): IV8Window {
    return this.getWindow();
  }

  static setUpLegacyAngularJsEnvs(env: IV8Environemnt) {
    (window as IV8Window).__V8ENV = env;
  }

  createBridge() {
    this.nativeWindow.bridge = {
      zone: this.zone,
      store: {
        getFacade: this.facadeRegister.getFacade.bind(this.facadeRegister),
      },
    };
  }

  setUpWindowUtils() {
    this.nativeWindow.migratonUtils = {
      setFeatureFlag: this.migrationFeatureFlagService.setFlag.bind(
        this.migrationFeatureFlagService
      ),
    };
  }

  private getWindow(): IV8Window {
    // return the global native browser window object
    return window;
  }
}

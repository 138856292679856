import { Injectable } from '@angular/core';

import { AuthService as Auth0AuthService } from '@auth0/auth0-angular';
import { filter, lastValueFrom, map, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  get auth0() {
    return this.auth0AuthService;
  }

  constructor(private auth0AuthService: Auth0AuthService) {}

  getToken() {
    return this.auth0AuthService.idTokenClaims$.pipe(
      take(1),
      map(user => user?.__raw)
    );
  }

  /**
   * @legacyMigration
   * TODO we can do a decorator to create promisify observables,
   * sometimes on the legacy app you have to return a promise
   */
  getToken_promise() {
    return lastValueFrom(this.getToken());
  }

  getAuth0User() {
    return this.auth0.isLoading$.pipe(
      filter(isLoading => isLoading === false),
      take(1),
      switchMap(() => this.auth0.getUser())
    );
  }
}

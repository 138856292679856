/**
 * Interface for the 'Projects' data
 */
export interface ProjectsEntity {
  id: string;
  name: string;
  description: string;
  type: string;
  projectBarChart: ProjectBarChart;
  projectAreaChart: ProjectAreaChart;
  projectDonutChart: ProjectDonutChart;
  metrics: Metrics;
  options: Options;
  createdAt: Date;
  updatedAt: Date;
  deletedStmtTransactions: number;
  logoFileName?: any;
}

export interface ProjectsMetaData {
  limit: number;
  skip: number;
  total: number;
}

interface Options {
  normalizePayee: boolean;
  normalizePayor: boolean;
  enforceWithdrawalFirst?: boolean;
  internalTransferMatchBest?: boolean;
}

interface Metrics {
  accounts: number;
  checkItems: number;
  checkPages: number;
  statements: number;
  totalPages: number;
  accountPeriods: number;
  apTransactions: number;
  arTransactions: number;
  brokeragePages: number;
  glTransactions: number;
  statementPages: number;
  bankTransactions: number;
  listTransactions: number;
  stmtTransactions: number;
  processingActivity: boolean;
  statementsInProgress: number;
  workpapersInProgress: number;
  erpExtractsInProgress: number;
  checkPagesWithoutChecks: number;
  reconciliationsInProgress: number;
  statementPagesWithoutTxns: number;
}

interface ProjectBarChart {}

interface ProjectAreaChart {}

interface ProjectDonutChart {}

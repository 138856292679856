import { MigrationFeatureFlagsKeys } from '@core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CORE_FEATURE_KEY, State } from './core.reducer';

// Lookup the 'Core' feature state managed by NgRx
export const getCoreState = createFeatureSelector<State>(CORE_FEATURE_KEY);

export const getAllMigrationFeatureFlags = createSelector(
  getCoreState,
  (state: State) => state.migrationFeatureFlags
);

export const getActivatedMigrationFeatureFlags = createSelector(
  getCoreState,
  getAllMigrationFeatureFlags,
  (_, allFeatureFlags) => {
    const enabledFeatureFlags = { ...allFeatureFlags };
    Object.keys(allFeatureFlags).forEach(key => {
      if (enabledFeatureFlags[key as MigrationFeatureFlagsKeys] === false) {
        delete enabledFeatureFlags[key as MigrationFeatureFlagsKeys];
      }
    });

    return enabledFeatureFlags;
  }
);

export const getLoggedUser = createSelector(
  getCoreState,
  (state: State) => state.loggedUser
);

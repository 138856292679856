import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs';

import {
  actionLegacySupport,
  selectorLegacySupport,
} from '../facade-decorators';
import * as CoreActions from './core.actions';
import * as CoreSelectors from './core.selectors';

@Injectable()
export class CoreFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  @selectorLegacySupport()
  allActivatedMigrationFeatureFlags$ = this.store.pipe(
    select(CoreSelectors.getActivatedMigrationFeatureFlags)
  );

  @selectorLegacySupport({ promisify: true })
  loggedUser$ = this.store.pipe(
    select(CoreSelectors.getLoggedUser),
    filter(user => !!user)
  );

  constructor(private readonly store: Store) {}

  loadMigrationFeatureFlags() {
    this.store.dispatch(CoreActions.initMigrationFeatureFlags());
  }

  @actionLegacySupport()
  logIn() {
    this.store.dispatch(CoreActions.authLogIn());
  }

  @actionLegacySupport()
  logOut() {
    this.store.dispatch(CoreActions.authLogOut());
  }

  loadUserInfo() {
    this.store.dispatch(CoreActions.loadUserInfo());
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NoopComponent } from './noop/noop.component';

@NgModule({
  declarations: [NoopComponent],
  exports: [NoopComponent],
  imports: [CommonModule],
})
export class SharedModule {}

import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as ProjectsActions from './projects.actions';
import { ProjectsEntity } from './projects.models';

export const PROJECTS_FEATURE_KEY = 'projects';

export interface State extends EntityState<ProjectsEntity> {
  selectedId?: string; // which Project record has been selected
  loaded: boolean; // has the Projects list been loaded
  error?: string | null; // last known error (if any)
}

export interface ProjectsPartialState {
  readonly [PROJECTS_FEATURE_KEY]: State;
}

export const projectsAdapter: EntityAdapter<ProjectsEntity> =
  createEntityAdapter<ProjectsEntity>();

export const initialState: State = projectsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const projectsReducer = createReducer(
  initialState,
  on(ProjectsActions.findAll, state => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(ProjectsActions.findAllProjectsSuccess, (state, { projects }) =>
    projectsAdapter.setAll(projects, { ...state, loaded: true })
  ),
  on(ProjectsActions.findAllProjectsFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: true,
  })),
  on(ProjectsActions.selectProject, (state, { projectId }) => ({
    ...state,
    selectedId: projectId,
  })),
  on(ProjectsActions.find, state => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(ProjectsActions.findProjectsSuccess, (state, { project }) =>
    projectsAdapter.setOne(project, {
      ...state,
      loaded: true,
    })
  ),
  on(ProjectsActions.findProjectsFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: true,
  })),
  on(
    ProjectsActions.updateProjectSuccess,
    (state, { projectId, updatedProject }) =>
      projectsAdapter.updateOne(
        { id: projectId, changes: updatedProject },
        state
      )
  ),
  on(ProjectsActions.updateProjectFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: true,
  })),
  on(ProjectsActions.createProjectSuccess, (state, { createdProject }) =>
    projectsAdapter.addOne(createdProject, state)
  ),
  on(ProjectsActions.createProjectFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: true,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return projectsReducer(state, action);
}

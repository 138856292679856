import { Component, Input } from '@angular/core';

@Component({
  selector: 'val-sb-test',
  templateUrl: './sb-test.component.html',
  styleUrls: ['./sb-test.component.scss'],
})
export class SbTestComponent {
  @Input()
  text = '';

  constructor() {}
}

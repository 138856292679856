import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';

import { environment } from '@environment';
import { CoreFacade } from '../../+state';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private coreFacade: CoreFacade) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: any, caught) => {
        if (
          err instanceof HttpErrorResponse &&
          err.url?.startsWith(environment.apiDomain) &&
          err.status === 401
        ) {
          this.coreFacade.logIn();
        }

        return throwError(() => new err());
      })
    );
  }
}

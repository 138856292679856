import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ProxyApiService } from '../proxy-api/proxy-api.service';
import { V8User } from '../+state/core.models';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(private proxyService: ProxyApiService) {}

  getAuthorization(userId: string) {
    return this.proxyService.get<V8User>(`authorization/${userId}`);
  }
}

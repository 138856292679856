import { AfterViewInit, Component, HostBinding, OnInit } from '@angular/core';
import {
  downgradeInjectable,
  setAngularJSGlobal,
  UpgradeModule,
} from '@angular/upgrade/static';
import { Data } from '@angular/router';

import { RouteIntegrationService, WindowRefService } from '@core';
import { CoreFacade } from './core/+state';
import { AuthService } from './core/authentication/auth.service';

@Component({
  selector: 'val-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit {
  @HostBinding('class.has-noop') hasNoopClass = false;

  constructor(
    private upgrade: UpgradeModule,
    private routeIntegrationService: RouteIntegrationService,
    private windowRef: WindowRefService,
    private coreFacade: CoreFacade
  ) {
    const ng = this.windowRef.nativeWindow.angular;
    setAngularJSGlobal(ng);

    this.downgradeServices(ng);

    this.routeIntegrationService.watchRouteChanges(data =>
      this.toogleNoopClass(data)
    );
  }

  ngOnInit(): void {
    this.coreFacade.loadUserInfo();
  }

  ngAfterViewInit(): void {
    console.log('App Component View is Present');

    /**
     * In order to allow for bootstrapping after setup
     */
    // Bootstrap AngularJS through UpgradeModule
    this.upgrade.bootstrap(document.body.parentElement as HTMLElement, [
      'v8-frontend',
    ]);
    this.routeIntegrationService.setUpLocationSync();
  }

  private toogleNoopClass(data: Data) {
    this.hasNoopClass = data.isWildcard === true;
  }

  private downgradeServices(ng: angular.IAngularStatic) {
    this.routeIntegrationService.legacySetUp();

    ng.module('core')
      // Downgraded Injectables
      // https://angular.io/guide/upgrade#making-angular-dependencies-injectable-to-angularjs
      .factory('newAuthSevice', downgradeInjectable(AuthService));
  }
}

import { Injectable } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';

import { of } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '@environment';
import { migrationFeatureFlagsRoutes } from '../../app-routing.module';

import {
  MigrationFeatureFlagsKeys,
  MigrationFlagService,
} from '../migration-flag/migration-flag.service';
import * as CoreActions from './core.actions';
import { CoreFacade } from './core.facade';
import { AuthService } from '../authentication/auth.service';
import { AuthorizationService } from '../authorization/authorization.service';
import { ServiceLocator } from '../service-locator.service';

@Injectable()
export class CoreEffects {
  initMigrationFlags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.initMigrationFeatureFlags),
      map(() => {
        const allMigrationFeatureFlags =
          this.migrationFeatureFlagsService.getAllFlags();

        return CoreActions.loadMigrationFeatureFlagsSuccess({
          featureFlags: allMigrationFeatureFlags,
        });
      })
    )
  );

  // Re-set routes when the feature flags routes are set
  resetRoutingPathsAccordingMigrationFeatureFlags$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.loadMigrationFeatureFlagsSuccess),
        // Select from store the activated migration feature flag
        withLatestFrom(this.facade.allActivatedMigrationFeatureFlags$),
        // Transform to Migration Routes[] but only the active ones
        map(([_, activatedMigrationFeatureFlags]) =>
          Object.keys(activatedMigrationFeatureFlags).reduce((acc, key) => {
            const activatedRoute =
              migrationFeatureFlagsRoutes[key as MigrationFeatureFlagsKeys];

            if (activatedRoute) {
              acc.push(activatedRoute);
            }

            return acc;
          }, [] as Routes)
        ),
        map(enabledMigrationFeatureFlagRoutes => ({
          enabledMigrationFeatureFlagRoutes,
          currentRoutes: this.router.config,
          newRoutes: [
            ...enabledMigrationFeatureFlagRoutes,
            ...this.router.config,
          ],
        })),
        // Log
        tap(customRoutes => {
          if (environment.production === false) {
            console.group('Migration Feature Flag');
            console.log(customRoutes.enabledMigrationFeatureFlagRoutes);
            console.group('How look the new routes');
            console.log(customRoutes.newRoutes);
            console.groupEnd();
            console.groupEnd();
          }
        }),
        // Set the new routes
        tap(({ newRoutes }) => this.router.resetConfig(newRoutes))
      ),
    { dispatch: false }
  );

  logIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.authLogIn),
        tap(() => {
          this.router.navigateByUrl('/login');
          this.authService.auth0.loginWithRedirect();
        })
      ),
    { dispatch: false }
  );

  logOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.authLogOut),
        tap(() =>
          this.authService.auth0.logout({
            returnTo: environment.logoutRedirectUrl,
          })
        )
      ),
    { dispatch: false }
  );

  loadUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.loadUserInfo),
      fetch({
        run: action =>
          this.authService.getAuth0User().pipe(
            switchMap(user => {
              // If the user doesn't exists, log in again
              if (user == undefined || user.sub == undefined) {
                return of(CoreActions.authLogIn());
              }

              return this.authorizationService.getAuthorization(user.sub).pipe(
                map(loggedUser =>
                  CoreActions.loadUserInfoSuccess({
                    loggedUser,
                  })
                )
              );
            })
          ),
        onError: (action, error) => {
          console.error('Error', error);
          return CoreActions.loadUserInfoFailure({
            error: JSON.parse(JSON.stringify(error)),
          });
        },
      })
    )
  );

  /**
   * Set the logged user to the legacy authorization service
   */
  setloadedUserToLegacyService$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.loadUserInfoSuccess),
        switchMap(({ loggedUser }) =>
          ServiceLocator.whenLegacyInjectorReady().pipe(
            map($injector => ({
              loggedUser,
              $injector,
            }))
          )
        ),
        tap(({ loggedUser, $injector }) => {
          const legacyAuthorizationFactory: any =
            $injector.get('Authorization');
          legacyAuthorizationFactory.setUserProfile(loggedUser);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private facade: CoreFacade,
    private router: Router,
    private migrationFeatureFlagsService: MigrationFlagService,
    private authService: AuthService,
    private authorizationService: AuthorizationService
  ) {}
}

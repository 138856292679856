import { createAction, props } from '@ngrx/store';

import { IMigrationFeatureFlags } from '../migration-flag/migration-flag.service';
import { V8User } from './core.models';

export const initMigrationFeatureFlags = createAction(
  '[Core/Migration] Init Migration Feature Flags'
);

export const loadMigrationFeatureFlagsSuccess = createAction(
  '[Core/Migration] Load Migration Feature Flags Success',
  props<{ featureFlags: IMigrationFeatureFlags }>()
);

export const loadUserInfo = createAction('[Core/Init] load user info');

export const loadUserInfoSuccess = createAction(
  '[Core/Init] Load User Info Success',
  props<{ loggedUser: V8User }>()
);

export const loadUserInfoFailure = createAction(
  '[Core/Init] Load User Info Failure',
  props<{ error: any }>()
);

export const authLogIn = createAction('[Core/Auth] Log In');

export const authLogOut = createAction('[Core/Auth] Log Out');

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environment';

export interface BackendResponse<T, M = {}> {
  id?: string;
  data: T;
  metadata: M;
}

@Injectable({
  providedIn: 'root',
})
export class ProxyApiService {
  private readonly baseURL: string;

  constructor(private http: HttpClient) {
    this.baseURL = ProxyApiService.getBaseURL();
  }

  /**
   *
   * @returns 'https://development.api.valid8.io/v1'
   */
  static getBaseURL(): string {
    return `${environment.apiDomain}/${environment.apiVersion}`;
  }

  get<T>(
    resource: Parameters<HttpClient['get']>[0],
    options?: Parameters<HttpClient['get']>[1]
  ): Observable<T>;
  get<T, M>(
    resource: Parameters<HttpClient['get']>[0],
    options?: Parameters<HttpClient['get']>[1]
  ): Observable<{ data: T; metadata: M }>;
  get<T, M>(
    resource: Parameters<HttpClient['get']>[0],
    options?: Parameters<HttpClient['get']>[1]
  ): Observable<T | { data: T; metadata: M }> {
    const fullUrl = this.buildFullUrl(resource);

    return this.http
      .get<BackendResponse<T, M>>(fullUrl, options)
      .pipe(this.handleResponse.bind(this));
  }

  post<T>(
    resource: Parameters<HttpClient['post']>[0],
    body: Parameters<HttpClient['post']>[1],
    options?: Parameters<HttpClient['post']>[2]
  ): Observable<T>;
  post<T, M>(
    resource: Parameters<HttpClient['post']>[0],
    body: Parameters<HttpClient['post']>[1],
    options?: Parameters<HttpClient['post']>[2]
  ): Observable<{ data: T; metadata: M }>;
  post<T, M>(
    resource: Parameters<HttpClient['post']>[0],
    body: Parameters<HttpClient['post']>[1],
    options?: Parameters<HttpClient['post']>[2]
  ): Observable<T | { data: T; metadata: M }> {
    const fullUrl = this.buildFullUrl(resource);

    return this.http
      .post<BackendResponse<T, M>>(fullUrl, body, options)
      .pipe(this.handleResponse.bind(this));
  }

  put<T>(
    resource: Parameters<HttpClient['put']>[0],
    body: Parameters<HttpClient['put']>[1],
    options?: Parameters<HttpClient['put']>[2]
  ): Observable<T>;
  put<T, M>(
    resource: Parameters<HttpClient['put']>[0],
    body: Parameters<HttpClient['put']>[1],
    options?: Parameters<HttpClient['put']>[2]
  ): Observable<{ data: T; metadata: M }>;
  put<T, M>(
    resource: Parameters<HttpClient['put']>[0],
    body: Parameters<HttpClient['put']>[1],
    options?: Parameters<HttpClient['put']>[2]
  ): Observable<T | { data: T; metadata: M }> {
    const fullUrl = this.buildFullUrl(resource);

    return this.http
      .put<BackendResponse<T, M>>(fullUrl, body, options)
      .pipe(this.handleResponse.bind(this));
  }

  private handleResponse<T, M>(
    response: Observable<BackendResponse<T, M>>
  ): Observable<T | { data: T; metadata: M }> {
    return response.pipe(
      map(response => {
        if (this.isEqualToEmptyObject(response.metadata)) {
          // NB: Checking for response.id here because there are endpoints where the response contains
          // a .data which is part of the base object and not a container for the overall response data
          // and we should return the full object there
          return response.data && !response.id ? response.data : response;
        }

        return { data: response.data, metadata: response.metadata };
      })
    );
  }

  private isEqualToEmptyObject(objectToCompare: unknown): boolean {
    return (
      objectToCompare === undefined ||
      JSON.stringify(objectToCompare) === JSON.stringify({})
    );
  }

  private buildFullUrl(resource: string): string {
    return `${this.baseURL}/${resource}`;
  }
}

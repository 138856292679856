import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  Data,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { downgradeInjectable, UpgradeModule } from '@angular/upgrade/static';
import { $locationShim } from '@angular/common/upgrade';
import { Location } from '@angular/common';
import { debounceTime, filter, map, switchMap, take } from 'rxjs/operators';

import { WindowRefService } from '@core';

@Injectable({
  providedIn: 'root',
})
export class RouteIntegrationService {
  /**
   * This anchor is declared here cache it. Will be treated as singleton
   */
  private anchor: HTMLAnchorElement;

  /**
   * This regex takes into account the different possibilites of visiting the home URL
   *
   * @example
   * - /
   * - /?someVars=true
   * - /?supportSignUp=true&supportForgotPassword=true&email=diego@herodevs.com&message=This%20account%20is%20already%20verified.&success=false&code=already-verified
   */
  private readonly homeURLRegex = /^\/(\?.*)?$/;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private windowRef: WindowRefService,
    private upgrade: UpgradeModule,
    private location: Location
  ) {
    this.anchor = document.createElement('a');
  }

  watchRouteChanges(onNavEnd: (data: Data) => void): void {
    // Watch initial load, redirect to projects
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationStart),
        // On the first page load, and the default URL
        filter(e => this.homeURLRegex.test((e as NavigationStart).url)),
        // Wait 1ms to let current route finish loading, otherwise we cancel the current route change, which can cause errors
        debounceTime(1)
      )
      .subscribe(e => {
        // Replace so that the empty route isn't in history
        this.router.navigateByUrl('/projects', { replaceUrl: true });
      });

    // Watch NavigationEnd, if route is not NoopComponent, hide main-content
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map(() => this.route),
        filter(
          route => route.firstChild !== undefined && route.firstChild !== null
        ),
        switchMap(route => (route.firstChild as ActivatedRoute).data)
      )
      .subscribe(onNavEnd);
  }

  legacySetUp() {
    const ng = this.windowRef.nativeWindow.angular;

    ng.module('v8-frontend')
      // Downgraded Injectables
      // https://angular.io/guide/upgrade#making-angular-dependencies-injectable-to-angularjs
      .factory('$location', downgradeInjectable($locationShim));
  }

  /**
   * Custom setUpLocationSync function, as the default create an endless loop
   * Based on the setUpLocationSync from upgrade source https://github.com/angular/angular/blob/master/packages/router/upgrade/src/upgrade.ts#L65
   */
  setUpLocationSync() {
    let prevPath: string | null = null;
    let timeout: number | null;

    this.upgrade.$injector
      .get('$rootScope')
      .$on('$locationChangeStart', (_: any, next: string, __: string) => {
        const url = this.resolveUrl(next);
        const path = this.location.normalize(url.pathname);
        const nextPath = path + url.search + url.hash;

        // Store previous path and bail if we're already on the same route. Prevents endless route thrashing
        if (nextPath !== prevPath) {
          prevPath = nextPath;

          // If we find issues in the future where certain routes need this, we can add an if-statement to set this to false.
          const options = { skipLocationChange: true };

          // Sync the Angular route to the AngularJS route async, so that AngularJS route can finish
          // This prevents current route from cancelling, which can cause errors
          // Also, since we clear the timeout, if this gets calls twice rapidly, we cancel the prev and only do the second
          timeout && clearTimeout(timeout);
          timeout = this.windowRef.nativeWindow.setTimeout(() => {
            timeout = null;
            this.router.navigateByUrl(nextPath, options);
          }, 1);
        }
      });
  }

  private resolveUrl(url: string): {
    pathname: string;
    search: string;
    hash: string;
  } {
    this.anchor.setAttribute('href', url);
    this.anchor.setAttribute('href', this.anchor.href);

    return {
      // IE does not start `pathname` with `/` like other browsers.
      pathname: `/${this.anchor.pathname.replace(/^\//, '')}`,
      search: this.anchor.search,
      hash: this.anchor.hash,
    };
  }
}

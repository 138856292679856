import { Injectable } from '@angular/core';

import { SessionStorageService } from '@core';
import { environment } from '@environment';

// Add here any feature flag
const mFFlagsKeys = [
  /**
   * URL admin/customer/{:customerId}/users
   */
  'adminCustomer',
] as const;

export type MigrationFeatureFlagsKeys = typeof mFFlagsKeys[number];

export type IMigrationFeatureFlags<T = boolean> = {
  [key in MigrationFeatureFlagsKeys]?: T;
};

@Injectable({
  providedIn: 'root',
})
export class MigrationFlagService {
  private readonly sessionStorageKeyPrefix = 'migration-feature-flag-';

  constructor(private sessionStorageService: SessionStorageService) {}

  getAllFlags(): IMigrationFeatureFlags {
    return (
      mFFlagsKeys
        // Get all key's values
        .map(key => ({
          featureFlagKey: key,
          value: this.getFlag(key),
        }))
        .reduce((acc, { featureFlagKey, value }) => {
          acc[featureFlagKey] = value;

          return acc;
        }, {} as IMigrationFeatureFlags)
    );
  }

  getFlag(flag: MigrationFeatureFlagsKeys): boolean {
    const sessionStorageKey = this.buildFlag(flag);
    const sessionStorageFlag = this.sessionStorageService.getItem(
      sessionStorageKey
    ) as boolean;

    return sessionStorageFlag != null
      ? sessionStorageFlag
      : !!environment.featureFlags.migration[flag];
  }

  setFlag(flag: MigrationFeatureFlagsKeys, value: boolean): void {
    const sessionStorageKey = this.buildFlag(flag);

    this.sessionStorageService.setItem(sessionStorageKey, value);
  }

  private buildFlag(flag: MigrationFeatureFlagsKeys) {
    return `${this.sessionStorageKeyPrefix}${flag}`;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SbTestComponent } from './sb-test/sb-test.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    SbTestComponent
  ],
  exports: [],
})
export class ComponentsModule {}

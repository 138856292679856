import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '@environment';

export interface V8State {}

export const reducers: ActionReducerMap<V8State> = {};

// console.log all actions
export function logger(
  reducer: ActionReducer<V8State>
): ActionReducer<V8State> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

export const metaReducers: MetaReducer<V8State>[] = !environment.production
  ? [logger]
  : [];

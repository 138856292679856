import { IMigrationFeatureFlags } from '@core';

export interface IV8Environemnt {
  apiDomain: string;
  apiVersion: string;
  authProviderClientID: string;
  authProviderDomain: string;
  authProviderRedirectUrl: string;
  logoutRedirectUrl: string;
  defaultCurrency: string;
  helpLink: string;
  csvTemplateHelpLink: string;
  defaultPageSize: number;
  defaultCheckPageSize: number;
  featureFlags: {
    checks: boolean;
    sankey: boolean;
    migration: IMigrationFeatureFlags;
  };
}

import { IRootScopeService } from 'angular';

import {
  Observable,
  lastValueFrom,
  map,
  switchMap,
  take,
  tap,
  filter,
} from 'rxjs';

import { ServiceLocator } from '../service-locator.service';

const subfix = '_legacySupport';

interface LegacySelectorSupportDecoratorOptions {
  /**
   * Creates a function with the same name with the subfix "_legacySupport_promise" to
   * that returns a promise of the selector
   *
   *  * @usage
   * ```js
   * // Somewhere on legacy app
   * var facade = bridge.store.getFacade('facade');
   * facade.loaded$_legacySupport_promise()
   *   .then(console.log)
   * ```
   */
  promisify?: true;
}

/**
 * Options available on the injected `_legacySupport` function
 */
interface LegacySupportFunctionOptions {
  /**
   * Adds to the pipe of the observable the operator [`take`](https://rxjs.dev/api/operators/take)
   */
  take?: number;
  /**
   * Adds to the pipe of the observable the operator [`filter`](https://rxjs.dev/api/operators/filter) to filter the elements that are `null` or `undefined`
   */
  differentOfNil?: boolean;
  /**
   * Flag to create a copy of the selector data.
   * We don't know how the legacy component uses this data, if it tries to modify it is going to launch a runtime error. To prevent that
   * scenario the `createStoreDataCopy` set this option to true as default.
   */
  createStoreDataCopy?: boolean;
}

/**
 * Selector to put to the selectors' facade.
 * This makes the observables compatible when are run from the legacy app.
 *
 * This will create a function with the same name with the subfix "_legacySupport"
 * with all the things needed when is run from the legacy app
 *
 * @example
 * ```ts
 * class facade {
 *   @selectorLegacySupport()
 *   loaded$ = this.store.pipe(
 *     select(selectors.randomSelector)
 *   );
 * }
 * ```
 *
 * @usage
 * ```js
 * // Somewhere on legacy app
 * var facade = bridge.store.getFacade('facade');
 * facade.loaded$_legacySupport()
 *   .subscribe(console.log)
 * ```
 *
 */
export function selectorLegacySupport(
  options?: LegacySelectorSupportDecoratorOptions
) {
  return (target: any, propName: string) => {
    injectApplyAsyncToObservable(target, propName);

    if (options?.promisify) {
      const methodName = `${propName}${subfix}`;

      target[`${methodName}_promise`] = function (
        options?: LegacySupportFunctionOptions
      ) {
        const observable: Observable<unknown> = this[methodName](options);

        return lastValueFrom(observable.pipe(take(1)));
      };
    }
  };
}

function injectApplyAsyncToObservable(target: any, propName: string) {
  target[`${propName}_legacySupport`] = function (
    options?: LegacySupportFunctionOptions
  ): Observable<unknown> {
    let observable = (this[propName] as Observable<unknown>).pipe(
      switchMap(data =>
        ServiceLocator.whenLegacyInjectorReady().pipe(
          tap($injector => {
            const $rootScope: IRootScopeService = $injector.get('$rootScope');
            $rootScope.$applyAsync();
          }),
          map(() => data)
        )
      )
    );

    if (options?.differentOfNil) {
      observable = observable.pipe(filter(data => data != undefined));
    }

    if (
      options?.createStoreDataCopy === undefined ||
      options?.createStoreDataCopy === true
    ) {
      observable = observable.pipe(
        map(data => (data ? JSON.parse(JSON.stringify(data)) : data))
      );
    }

    if (options?.take) {
      observable = observable.pipe(take(options.take));
    }

    return observable;
  };
}

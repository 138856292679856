import { NgZone } from '@angular/core';
import { ServiceLocator } from '../service-locator.service';

const subfix = '_legacySupport';

/**
 * Decorator to put in the NgRx facade methods who invokes an action.
 * This makes the methods compatible when are run from the legacy app.
 *
 * This will create a function with the same name with the subfix "_legacySupport"
 * with all the things needed when is run from the legacy app
 *
 * @example
 * ```ts
 * class facade {
 *   @actionLegacySupport()
 *   methodThatCallAnAction() { };
 * }
 * ```
 *
 * @usage
 * ```js
 * // Somewhere on legacy app
 * var facade = bridge.store.getFacade('facade');
 * facade.methodThatCallAnAction_legacySupport();
 * ```
 */
export function actionLegacySupport() {
  return (
    target: Object,
    methodName: string,
    descriptor: PropertyDescriptor
  ) => {
    const originalMethod = descriptor.value as Function;
    const runInsideAngularZoneFn = function (this: any, ...args: any[]) {
      ServiceLocator.whenInjectorReady().subscribe(injector => {
        const ngZone = injector.get(NgZone);

        ngZone.run(() => {
          originalMethod.call(this, ...args);
        });
      });
    };

    (target as any)[`${methodName}${subfix}`] = runInsideAngularZoneFn;
  };
}

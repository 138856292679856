/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from '../authentication/auth-interceptor/auth-interceptor.service';
import { LegacyLoaderInterceptor } from './legacy-loader/legacy-loader.interceptor';
import { UnauthorizedInterceptor } from './unauthorized/unauthorized.interceptor';

/**
 * Http interceptor providers in outside-in order
 * @see https://angular.io/guide/http#interceptor-order
 */
export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LegacyLoaderInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: UnauthorizedInterceptor,
    multi: true,
  },
];

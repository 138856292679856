import { Injectable } from '@angular/core';
import { ProxyApiService } from '@core';
import { map, Observable } from 'rxjs';
import {
  ProjectsEntity,
  ProjectsMetaData,
} from '../../projects/+state/projects.models';

export type NewProjectRequest = Pick<
  ProjectsEntity,
  'description' | 'name' | 'type' | 'options'
> & { customerId: string };

export interface NewProjectResponse extends ProjectsEntity {
  customerId: string;
  filePath: string;
  bankAPIData?: any;
}

@Injectable()
export class ProjectsService {
  private readonly resource = 'projects';

  constructor(private proxyService: ProxyApiService) {}

  findProject(projectId: string): Observable<ProjectsEntity> {
    return this.proxyService.get<ProjectsEntity>(
      `${this.resource}/${projectId}`
    );
  }

  findAllProjects$(params: {
    customerId: string;
    $limit: number;
  }): Observable<ProjectsEntity[]> {
    return this.proxyService
      .get<ProjectsEntity[], ProjectsMetaData>(this.resource, {
        params,
      })
      .pipe(map(({ data }) => data));
  }

  createProject(newProject: NewProjectRequest) {
    return this.proxyService.post<NewProjectResponse>(
      this.resource,
      newProject
    );
  }

  updateProject(projectId: string, projectData: Partial<ProjectsEntity>) {
    return this.proxyService.put<Partial<ProjectsEntity>>(
      `${this.resource}/${projectId}`,
      projectData
    );
  }
}

import { Injectable } from '@angular/core';
import { actionLegacySupport, selectorLegacySupport } from '@core';
import { ofType, Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs';

import { NewProjectRequest } from '../shared';
import * as ProjectsActions from './projects.actions';
import { ProjectsEntity } from './projects.models';
import * as ProjectsFeature from './projects.reducer';
import * as ProjectsSelectors from './projects.selectors';

@Injectable()
export class ProjectsFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(ProjectsSelectors.getLoaded));
  allProjects$ = this.store.pipe(select(ProjectsSelectors.getAllProjects));
  selectedProjects$ = this.store.pipe(select(ProjectsSelectors.getSelected));

  @selectorLegacySupport({ promisify: true })
  allLoadedProjects$ = this.store.pipe(
    select(ProjectsSelectors.getAllLoadedProjects),
    filter(({ loaded }) => loaded === true),
    map(({ projects }) => projects)
  );

  /**
   * @legacyMigration
   */
  @selectorLegacySupport()
  nextUpdateProjectSuccess$ = this.actions$.pipe(
    ofType(ProjectsActions.updateProjectSuccess),
    take(1)
  );

  /**
   * @legacyMigration
   */
  @selectorLegacySupport()
  nextUpdateProjectFailure$ = this.actions$.pipe(
    ofType(ProjectsActions.updateProjectFailure),
    take(1)
  );

  /**
   * @legacyMigration
   */
  @selectorLegacySupport()
  nextCreateProjectSuccess$ = this.actions$.pipe(
    ofType(ProjectsActions.createProjectSuccess),
    take(1)
  );

  /**
   * @legacyMigration
   */
  @selectorLegacySupport()
  nextCreateProjectFailure$ = this.actions$.pipe(
    ofType(ProjectsActions.createProjectFailure),
    take(1)
  );

  /**
   * @legacyMigration
   */
  @selectorLegacySupport({ promisify: true })
  getSelectedLoadedProject$ = this.store.pipe(
    select(ProjectsSelectors.getLoadedProject),
    filter(({ loaded }) => loaded === true),
    map(({ project }) => project)
  );

  /**
   * @legacyMigration
   */
  @selectorLegacySupport()
  nextFindProjectFailure$ = this.actions$.pipe(
    ofType(ProjectsActions.findProjectsFailure),
    take(1)
  );

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions
  ) {}

  @actionLegacySupport()
  findAll(customerId: string, limit: number) {
    this.store.dispatch(ProjectsActions.findAll({ customerId, limit }));
  }

  @actionLegacySupport()
  findProject(projectId: string) {
    this.store.dispatch(ProjectsActions.find({ projectId }));
  }

  @actionLegacySupport()
  updateProject(projectId: string, projectData: Partial<ProjectsEntity>) {
    this.store.dispatch(
      ProjectsActions.updateProject({
        projectId,
        projectData,
      })
    );
  }

  @actionLegacySupport()
  createProject(newProject: NewProjectRequest) {
    this.store.dispatch(ProjectsActions.createProject({ newProject }));
  }

  @actionLegacySupport()
  selectProject(projectId: string) {
    this.store.dispatch(ProjectsActions.selectProject({ projectId }));
  }
}

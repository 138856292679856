import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { UpgradeModule } from '@angular/upgrade/static';

import { finalize, Observable } from 'rxjs';

@Injectable()
export class LegacyLoaderInterceptor implements HttpInterceptor {
  private blockUiService: {
    start: () => void;
    stop: () => void;
    message: (m: string) => void;
  };

  constructor(private upgrade: UpgradeModule) {
    this.blockUiService = this.upgrade.$injector.get('blockUI');
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.blockUiService.start();

    const message = this.getMessage(request.method);
    this.blockUiService.message(message);

    return next
      .handle(request)
      .pipe(finalize(() => this.blockUiService.stop()));
  }

  private getMessage(method: 'GET' | 'POST' | 'DELETE' | 'PUT' | string) {
    switch (method) {
      case 'GET':
        return 'Loading';

      case 'POST':
        // TODO We want to block if this is one of the "filter posts" which use POST to get around GET
        // query string size limitations and will have things like pagination and filtering params
        // embedded within the request
        return 'Saving';

      case 'DELETE':
        return 'Removing';

      case 'PUT':
        return 'Updating';

      default:
        return 'Loading';
    }
  }
}

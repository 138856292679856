import {
  setUpLegacyAngularJsEnvs,
  IV8Environemnt,
  defaultEnvs,
  defaultFeatureFlags,
} from './utils';

const v8Env: IV8Environemnt = {
  ...defaultEnvs(),
  apiDomain: 'https://development.api.valid8.io',
  authProviderClientID: 'KY2BfaocMbSu0VID-gnLYMbBSNFpiIiT',
  authProviderDomain: 'dev-valid8.auth0.com',
  logoutRedirectUrl: 'https://dev-legacy.valid8.io',
  authProviderRedirectUrl: 'https://dev-legacy.valid8.io/callback',
  featureFlags: {
    ...defaultFeatureFlags(),
    checks: true,
    sankey: true,
  },
};

export const environment = {
  production: false,
  ...v8Env,
};

setUpLegacyAngularJsEnvs(v8Env);

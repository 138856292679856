import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'val-customer-user-management',
  templateUrl: './customer-user-management.component.html',
  styleUrls: ['./customer-user-management.component.scss'],
})
export class CustomerUserManagementComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { auto } from 'angular';

export class ServiceLocator {
  private static isInjectorReady$ = new BehaviorSubject(false);
  private static isLegacyInjectorReady$ = new BehaviorSubject(false);

  private static injector: Injector;
  /**
   * Legacy Injector
   */
  private static $injector: auto.IInjectorService;

  static setInjectorReady(injector: Injector) {
    this.injector = injector;
    this.isInjectorReady$.next(true);
  }

  static setLegacyInjectorReady(
    getLegacyInjector: () => auto.IInjectorService
  ) {
    const i = setInterval(() => {
      if (!getLegacyInjector()) {
        return;
      }

      this.$injector = getLegacyInjector();
      this.isLegacyInjectorReady$.next(true);

      clearInterval(i);
    }, 1);
  }

  static whenInjectorReady() {
    return this.isInjectorReady$.asObservable().pipe(
      filter(isInjectorLoaded => isInjectorLoaded === true),
      take(1),
      map(() => this.injector)
    );
  }

  static whenLegacyInjectorReady() {
    return this.isLegacyInjectorReady$.asObservable().pipe(
      filter(isLegacyInjectorLoaded => isLegacyInjectorLoaded === true),
      take(1),
      map(() => this.$injector)
    );
  }
}

import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromCore from './+state/core.reducer';
import { CoreEffects } from './+state/core.effects';
import { CoreFacade } from './+state/core.facade';
import { FacadeRegisterService } from './facade-register/facade-register.service';
import { ServiceLocator } from './service-locator.service';
import { AuthCallbackComponent, AuthLogInComponent } from './authentication';
import { CoreRoutingModule } from './core-routing.module';

@NgModule({
  declarations: [AuthCallbackComponent, AuthLogInComponent],
  imports: [
    CoreRoutingModule,
    UpgradeModule,
    CommonModule,
    StoreModule.forFeature(fromCore.CORE_FEATURE_KEY, fromCore.reducer),
    EffectsModule.forFeature([CoreEffects]),
  ],
  providers: [CoreFacade],
})
export class CoreModule {
  constructor(
    coreFacade: CoreFacade,
    facadeRegister: FacadeRegisterService,
    injector: Injector,
    upgrade: UpgradeModule
  ) {
    // Init service locator
    ServiceLocator.setInjectorReady(injector);
    ServiceLocator.setLegacyInjectorReady(() => upgrade.$injector);

    facadeRegister.registerFacade(fromCore.CORE_FEATURE_KEY, coreFacade);

    coreFacade.loadMigrationFeatureFlags();
  }
}

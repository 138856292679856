import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  PROJECTS_FEATURE_KEY,
  State,
  projectsAdapter,
} from './projects.reducer';

// Lookup the 'Projects' feature state managed by NgRx
export const getProjectsState =
  createFeatureSelector<State>(PROJECTS_FEATURE_KEY);

const { selectAll, selectEntities, selectIds } = projectsAdapter.getSelectors();

export const getLoaded = createSelector(
  getProjectsState,
  (state: State) => state.loaded
);

export const getProjectsError = createSelector(
  getProjectsState,
  (state: State) => state.error
);

export const getAllProjects = createSelector(getProjectsState, (state: State) =>
  selectAll(state)
);

export const getProjectsEntities = createSelector(
  getProjectsState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getProjectsState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getProjectsEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getAllLoadedProjects = createSelector(
  getLoaded,
  getAllProjects,
  (loaded, projects) => ({ loaded, projects })
);

export const getLoadedProject = createSelector(
  getLoaded,
  getSelected,
  (loaded, project) => ({ loaded, project })
);

import { NgModule, NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { LocationUpgradeModule } from '@angular/common/upgrade';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './components/components.module';
import { HttpClientModule } from '@angular/common/http';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthModule } from '@auth0/auth0-angular';

import {
  IV8Window,
  WindowRefService,
  CoreModule,
  httpInterceptorProviders,
  ServiceLocator,
} from '@core';
import { environment } from '@environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { reducers, metaReducers } from './reducers';
import { ProjectsModule } from './projects/projects.module';
import { auto } from 'angular';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    CommonModule,
    CoreModule,
    ComponentsModule,
    AppRoutingModule,
    UpgradeModule,
    LocationUpgradeModule.config(),
    SharedModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    AuthModule.forRoot({
      clientId: environment.authProviderClientID,
      domain: environment.authProviderDomain,
      redirectUri: environment.authProviderRedirectUrl,
      audience: 'https://' + environment.authProviderDomain + '/userinfo', // ??
      scope: 'openid email',
    }),
    ProjectsModule,
  ],
  providers: [
    httpInterceptorProviders,
    // Upgraded Dependencies
    // https://angular.io/guide/upgrade#making-angularjs-dependencies-injectable-to-angular
    {
      provide: '$scope',
      useExisting: '$rootScope',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private router: Router,
    private zone: NgZone,
    private windowRef: WindowRefService
  ) {
    this.windowRef.createBridge();
    this.windowRef.setUpWindowUtils();

    if (environment.production === false) {
      // this.debugRoutes();
      runTasksOutsideZone(this.zone, this.windowRef.nativeWindow);
      debugZone(this.zone);
    }
  }

  /*
  This function logs when routes changes, and when the two different frameworks are aware of the change.
  Logging different events will give you new insights into what is going on between the two routers. If
  you are seeing weird behavior, it is good to turn this on and see what the routes are doing.
   */
  private debugRoutes(): void {
    this.router.events
      .pipe(
        filter(
          e =>
            e instanceof NavigationStart ||
            e instanceof NavigationEnd ||
            e instanceof NavigationCancel
        )
      )
      .subscribe(console.log);

    ServiceLocator.whenLegacyInjectorReady().subscribe($injector => {
      this.debugAngularJS($injector);
    });
  }

  private debugAngularJS($injector: auto.IInjectorService): void {
    $injector
      .get('$rootScope')
      .$on('$locationChangeStart', (e: any, next: string, curr: string) => {
        console.log('$locationChangeStart', next, curr);
      });
    $injector
      .get('$rootScope')
      .$on('$locationChangeSuccess', (e: any, curr: string, prev: string) => {
        console.log('$locationChangeSuccess', curr, prev);
      });
  }
}

function runTasksOutsideZone(zone: NgZone, window: IV8Window): void {
  const raf = window.requestAnimationFrame;
  // @ts-ignore
  window.requestAnimationFrame = (...args: any[]) => {
    zone.runOutsideAngular(() => {
      // @ts-ignore
      raf.apply(window as any, args);
    });
  };
  // Keep these incase we need to find which piece of code is not letting the zone settle to zero macroTasks
  // const si = window.setInterval;
  // // @ts-ignore
  // window.setInterval = (...args: any[]) => {
  //   zone.runOutsideAngular(() => {
  //     // @ts-ignore
  //     si.apply(window as any, args);
  //   });
  // };
  //
  // const st = window.setTimeout;
  // // @ts-ignore
  // window.setTimeout = (...args: any[]) => {
  //   zone.runOutsideAngular(() => {
  //     // @ts-ignore
  //     st.apply(window as any, args);
  //   });
  // };
}

function debugZone(zone: NgZone): void {
  let pendingMacroCount = 0;
  zone.runOutsideAngular(() => {
    setInterval(() => {
      const { macroTasks } = (zone as any)._inner.parent.get(
        'TaskTrackingZone'
      );
      if (pendingMacroCount != macroTasks.length) {
        console.log(`PENDING TASKS: macro-${macroTasks.length}`);
      }
      pendingMacroCount = macroTasks.length;
    }, 5);
  });
}

import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs';
import { ProjectsService } from '../shared';

import * as ProjectsActions from './projects.actions';
import * as ProjectsFeature from './projects.reducer';

@Injectable()
export class ProjectsEffects {
  findAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.findAll),
      fetch({
        run: action => {
          return this.projectsService
            .findAllProjects$({
              customerId: action.customerId,
              $limit: action.limit,
            })
            .pipe(
              map(data =>
                ProjectsActions.findAllProjectsSuccess({
                  projects: data,
                })
              )
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return ProjectsActions.findAllProjectsFailure({
            error: JSON.parse(JSON.stringify(error)),
          });
        },
      })
    )
  );

  find$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.find),
      fetch({
        run: ({ projectId }) => {
          return this.projectsService.findProject(projectId).pipe(
            map(data =>
              ProjectsActions.findProjectsSuccess({
                project: data,
              })
            )
          );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return ProjectsActions.findProjectsFailure({
            error: JSON.parse(JSON.stringify(error)),
          });
        },
      })
    )
  );

  selectProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.selectProject),
      map(({ projectId }) => ProjectsActions.find({ projectId }))
    )
  );

  updateProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.updateProject),
      fetch({
        run: ({ projectId, projectData }) =>
          this.projectsService.updateProject(projectId, projectData).pipe(
            map(updatedProject =>
              ProjectsActions.updateProjectSuccess({
                projectId,
                updatedProject,
              })
            )
          ),
        onError: (action, error) => {
          console.error('Error', error);
          return ProjectsActions.updateProjectFailure({
            error: JSON.parse(JSON.stringify(error)),
          });
        },
      })
    )
  );

  createProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.createProject),
      fetch({
        run: ({ newProject }) =>
          this.projectsService.createProject(newProject).pipe(
            map(createdProject =>
              ProjectsActions.createProjectSuccess({
                createdProject,
              })
            )
          ),
        onError: (action, error) => {
          console.error('Error', error);
          return ProjectsActions.createProjectFailure({
            error: JSON.parse(JSON.stringify(error)),
          });
        },
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private projectsService: ProjectsService
  ) {}
}

import { WindowRefService } from '@core';
import { IV8Environemnt } from './environment.model';

/**
 * Legacy AngularJS code will still need these vars exposed globally
 * Code in the Angular app should just `import environment from 'environments/environment'`
 */
export function setUpLegacyAngularJsEnvs(env: IV8Environemnt) {
  WindowRefService.setUpLegacyAngularJsEnvs(env);
}

import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';

import { environment } from '@environment';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Authenticate only the request made to the backed
    if (req.url.startsWith(environment.apiDomain)) {
      return this.auth.getToken().pipe(
        switchMap(authToken => {
          if (!authToken) {
            return next.handle(req);
          }

          // Clone the request and replace the original headers with
          // cloned headers, updated with the authorization.
          const authReq = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${authToken}`),
          });

          // send cloned request with header to the next handler.
          return next.handle(authReq);
        })
      );
    }

    return next.handle(req);
  }
}

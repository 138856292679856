import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

@Component({
  selector: 'val-auth-callback',
  template: `<div>
    <img src="assets/images/loading.svg" alt="loading" />
  </div>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthCallbackComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

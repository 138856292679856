import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FacadeRegisterService } from '@core';
import * as fromProjects from './+state/projects.reducer';
import { ProjectsEffects } from './+state/projects.effects';
import { ProjectsFacade } from './+state/projects.facade';
import { ProjectsService } from './shared';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([ProjectsEffects]),
    StoreModule.forFeature(
      fromProjects.PROJECTS_FEATURE_KEY,
      fromProjects.reducer
    ),
  ],
  providers: [ProjectsFacade, ProjectsService],
})
export class ProjectsModule {
  constructor(
    projectsFacade: ProjectsFacade,
    facadeRegister: FacadeRegisterService
  ) {
    facadeRegister.registerFacade(
      fromProjects.PROJECTS_FEATURE_KEY,
      projectsFacade
    );
  }
}

import { createReducer, on, Action } from '@ngrx/store';

import { IMigrationFeatureFlags } from '../migration-flag/migration-flag.service';
import * as CoreActions from './core.actions';
import { V8User } from './core.models';

export const CORE_FEATURE_KEY = 'core';

export interface State {
  migrationFeatureFlags: IMigrationFeatureFlags;
  loggedUser: V8User | null;
}

export interface CorePartialState {
  readonly [CORE_FEATURE_KEY]: State;
}

export const initialState: State = {
  // set initial required properties
  migrationFeatureFlags: {},
  loggedUser: null,
};

const coreReducer = createReducer(
  initialState,
  on(
    CoreActions.loadMigrationFeatureFlagsSuccess,
    (state, { featureFlags }) => ({
      ...state,
      migrationFeatureFlags: featureFlags,
    })
  ),
  on(CoreActions.loadUserInfoSuccess, (state, { loggedUser }) => ({
    ...state,
    loggedUser,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return coreReducer(state, action);
}

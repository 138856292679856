import { IV8Environemnt } from './environment.model';

export function defaultEnvs(): Pick<
  IV8Environemnt,
  | 'apiVersion'
  | 'logoutRedirectUrl'
  | 'defaultCurrency'
  | 'helpLink'
  | 'csvTemplateHelpLink'
  | 'defaultPageSize'
  | 'defaultCheckPageSize'
> {
  return {
    apiVersion: 'v1',
    logoutRedirectUrl: 'https://www.valid8financial.com',
    defaultCurrency: 'USD',
    helpLink: 'https://customersuccess.valid8financial.com/knowledge',
    csvTemplateHelpLink:
      'https://customersuccess.valid8financial.com/knowledge/uploading-transaction-listings',
    defaultPageSize: 1000,
    defaultCheckPageSize: 100,
  };
}

export function defaultFeatureFlags(): IV8Environemnt['featureFlags'] {
  return {
    checks: false,
    sankey: false,
    migration: {
      ...defaultMigrationFeatureFlags(),
    },
  };
}

function defaultMigrationFeatureFlags(): IV8Environemnt['featureFlags']['migration'] {
  return {
    adminCustomer: false,
  };
}
